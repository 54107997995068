export class UserData {
  constructor(props: any) {
    this.fullName = `${props.firstName} ${props.lastName}`;
    this.firstName = props.firstName;
    this.lastName = props.lastName;
    this.mobile = props.mobile;
    this.email = props.email;
    this.profileImageURL = props.profileImageURL;
    this.registeredAt = props.registeredAt;
    this.hasPassword = props.hasPassword;
  }

  fullName: string;
  firstName: string;
  lastName: string;
  mobile: number;
  email: string;
  profileImageURL: string | null;
  registeredAt: number;
  hasPassword: boolean;

  get userFullName(): string {
    return this.fullName;
  }

  set userFullName(str: string) {
    this.fullName = str ? str : `${this.firstName} ${this.lastName}`;
  }
}
