import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'convertMinutes'
})
export class ConvertMinutesPipe implements PipeTransform {

  transform(value: number): string {
    const hours = value / 60;
    const rhours = Math.floor(hours);
    const minute = (hours - rhours) * 60;
    const rminute = Math.round(minute);

    return `${rhours} ساعت ${rminute < 1 ? '' : ` و ${rminute} دقیقه `}`;
    // return `${rhours}:${rminute < 10 ? '0' + rminute : rminute}`;
  }

}
