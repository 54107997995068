import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {mergeMap} from 'rxjs/operators';
import {empty} from 'rxjs';

import {CategoryService} from './modules/global/category/service/category.service';
import {TokenService} from '../shared/services/token.service';
import {MainService} from './main.service';
import {AuthService} from './auth/services/auth.service';
import {ConfigService} from '../shared/services/config.service';
import {LoadingService} from '../shared/services/loading.service';
import {UserData} from '../shared/models/user-data';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalGetPasswordComponent} from './auth/components/modal-get-password/modal-get-password.component';

@UntilDestroy()

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(private routed: ActivatedRoute,
              public router: Router,
              private categoryService: CategoryService,
              private tokenService: TokenService,
              private mainService: MainService,
              public authService: AuthService,
              public loadingService: LoadingService,
              private modalService: NgbModal,
              private configService: ConfigService) {
    this.tokenService.isAuthenticated$
    .pipe(
      untilDestroyed(this),
      mergeMap(x => {
        if (x) {
          return this.mainService.getUserData();
        }
        return empty();
      })
    )
    .subscribe(res => {
      if (res.success) {
        this.mainService.setUserData(new UserData(res.result));
        if (!this.mainService.isUserPasswordModalShowed() && !res.result.hasPassword) {
          this.modalService.open(ModalGetPasswordComponent, this.configService.modalOptions('default'));
          this.mainService.setUserPasswordModal(true);
        }
      }
    });
  }

  ngOnInit(): void {
  }
}
