import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {Subject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, mergeMap} from 'rxjs/operators';

import {SearchService} from '../../services/search.service';
import {SearchResultModel} from '../../model/search-result.model';
import {ConvertUrlPipe} from '../../../../pipes/convert-url.pipe';

@UntilDestroy()

@Component({
  selector: 'app-mobile-search',
  templateUrl: './mobile-search.component.html',
  styleUrls: ['./mobile-search.component.scss']
})
export class MobileSearchComponent implements OnInit {

  @Output() isClose = new EventEmitter<boolean>();
  isSearch = false;
  phraseValue$: Subject<string> = new Subject<string>();
  searchValue = '';
  resultSearch!: SearchResultModel;
  phrase!: string;

  constructor(private searchService: SearchService,
              public convertUrl: ConvertUrlPipe) {
  }

  closeEvent(): void {
    this.isClose.emit(false);
  }

  ngOnInit(): void {
    this.phraseValue$.pipe(
      debounceTime(500),
      mergeMap(res => {
        this.phrase = res;
        return this.searchService.getSearchList(decodeURI(res));
      }),
      untilDestroyed(this)
    ).subscribe(result => {
      if (result.success) {
        this.isSearch = true;
        this.resultSearch = result.result;
      }
    });
  }

  globalSearch(e: any): void {
    let searchString = e.target.value;
    searchString = searchString.trim();
    if (searchString.length > 2 && this.searchValue !== searchString) {
      this.phraseValue$.next(searchString);
      this.searchValue = searchString;
    }
    if (searchString.length <= 2) {
      this.isSearch = false;
      this.searchValue = '';
    }
  }

  onClickedInput(): void {
    if (!this.isSearch && this.searchValue.length > 2) {
      this.isSearch = true;
    }
  }


}
