import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastifyService {

  constructor(private toastService: ToastrService) {
  }


  success(message: string, title?: string, options?: object): void {
    this.toastService.success(
      message,
      title ? title : '',
      options ? options : undefined);
  }

  error(message: string, title?: string, options?: object): void {
    this.toastService.error(
      message,
      title ? title : '',
      options ? options : undefined);
  }

  warning(message: string, title?: string, options?: object): void {
    this.toastService.warning(
      message,
      title ? title : '',
      options ? options : undefined);
  }

  info(message: string, title?: string, options?: object): void {
    this.toastService.info(
      message,
      title ? title : '',
      options ? options : undefined);
  }
}
