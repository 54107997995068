import {Component, OnInit} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {MainService} from '../../main.service';
import {ToastifyService} from '../../../shared/services/toastify.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MainCategoryModel} from '../../modules/global/category/models/main-category.model';
import {CategoryService} from '../../modules/global/category/service/category.service';

@UntilDestroy()

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  newsLetters!: FormGroup;
  specialMenu: MainCategoryModel[] = [];

  constructor(private formBuilder: FormBuilder,
              private mainService: MainService,
              private categoryService: CategoryService,
              private toastifyService: ToastifyService) {

    categoryService.categoryList.forEach(f => {
      if (f.isSpecial) {
        this.specialMenu.push(f);
      }
    });
  }

  ngOnInit(): void {
    this.createNewsLetters();
  }


  private createNewsLetters(): void {
    this.newsLetters = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.pattern('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])')]),
    });
  }

  subscribeToNewsletter(e: any): void {
    const payload = JSON.stringify(e.email);
    this.newsLetters.reset();
    this.mainService.postNewsletter(payload)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res.success) {
          this.toastifyService.success('عضویت در خبرنامه با موفقیت انجام شد.');
        }
      });
  }

}
