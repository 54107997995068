import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {debounceTime, mergeMap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {SearchService} from '../../services/search.service';
import {SearchResultModel} from '../../model/search-result.model';
import {ConvertUrlPipe} from '../../../../pipes/convert-url.pipe';

@UntilDestroy()

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() mode = 'header';
  @ViewChild('searchInput', {static: false}) searchInput!: ElementRef;
  isSearch = false;
  phraseValue$: Subject<string> = new Subject<string>();
  searchValue = '';
  resultSearch!: SearchResultModel;
  searchBox!: string;
  isOpen = false;
  hasHome = false;
  phrase!: string;

  constructor(private searchService: SearchService,
              private router: Router,
              public convertUrl: ConvertUrlPipe) {
  }

  ngOnInit(): void {
    this.phraseValue$.pipe(
      debounceTime(500),
      mergeMap(res => {
        this.phrase = res;
        return this.searchService.getSearchList(decodeURI(res));
      }),
      untilDestroyed(this)
    ).subscribe(result => {
      if (result.success) {
        this.isSearch = true;
        this.resultSearch = result.result;
      }
    });

    if (this.mode !== 'header') {
      this.isOpen = true;
    } else {
      this.hasHome = (this.router.url === '/home' || this.router.url === '/');
      this.router.events
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res instanceof NavigationEnd) {
          this.closeSearchBox();
          this.hasHome = (this.router.url === '/home' || this.router.url === '/');
        }
      });
    }
  }

  openHandler(): void {
    if (this.mode === 'header') {
      this.isOpen = !this.isOpen;
      this.searchInput.nativeElement.focus();
      if (!this.isOpen) {
        this.closeSearchBox();
      }
    } else {
      this.isOpen = true;
    }
  }

  globalSearch(e: any): void {
    let searchString = e.target.value;
    searchString = searchString.trim();
    if (searchString.length > 2 && this.searchValue !== searchString) {
      this.phraseValue$.next(searchString);
      this.searchValue = searchString;
    }
    if (searchString.length <= 2) {
      this.isSearch = false;
      this.searchValue = '';
    }
  }

  onClickedOutside(e: any): void {
    if (this.isSearch) {
      this.isSearch = false;
    }
  }

  onClickedInput(): void {
    if (!this.isSearch && this.searchValue.length > 2) {
      this.isSearch = true;
    }
  }

  closeSearchBox(): void {
    this.searchBox = '';
    this.isSearch = false;
    this.searchValue = '';
    if (this.mode === 'header') {
      this.isOpen = false;
    }
  }

}
