import { Injectable, Pipe, PipeTransform} from '@angular/core';

import {UtilityService} from '../services/utility.service';

@Pipe({
  name: 'dateTime'
})

@Injectable({
  providedIn: 'root'
})

export class DateTimePipe implements PipeTransform {

  constructor(private utilityService: UtilityService) {
  }

  transform(value: any, pattern?: string): unknown {
    const expression = isNaN(value) ? value : `${value}`;
    const date = this.utilityService.convertStringToDateObject(expression);
    const time = this.utilityService.convertStringToTimeObject(expression);
    switch (pattern) {
      case 'withoutSecond' :
        return `${time.hour}:${time.minute} | ${date.year}/${date.month}/${date.day}`;
      default:
        return `${time.hour}:${time.minute}:${time.second} | ${date.year}/${date.month}/${date.day}`;
    }
  }

}
