import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'webinarSectionTitle'
})
export class WebinarSectionTitlePipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case 0 :
        return 'بخش اول';
      case 1 :
        return 'بخش دوم';
      case 2 :
        return 'بخش سوم';
      case 3 :
        return 'بخش چهارم';
      case 4 :
        return 'بخش پنجم';
      case 5 :
        return 'بخش ششم';
      case 6 :
        return 'بخش هفتم';
      case 7 :
        return 'بخش هشتم';
      case 8 :
        return 'بخش نهم';
      default:
        return `بخش ${value + 1} ام `;
    }
  }

}
