import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MainComponent} from './main/main.component';
import {HeaderComponent} from './main/layout/header/header.component';
import {MenuComponent} from './main/layout/header/menu/menu.component';
import {FooterComponent} from './main/layout/footer/footer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpInterceptorService} from './shared/services/http-interceptor.service';
import {SharedModule} from './shared/shared.module';
import {ToastrModule} from 'ngx-toastr';
import {CookieService} from 'ngx-cookie-service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GlobalSearchModule} from './shared/modules/global-search/global-search.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { BaseComponent } from './base/base.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    NotFoundComponent,
    BaseComponent,
    MaintenanceComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    GlobalSearchModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      progressBar: true,
    }),

  ],
  providers: [
    CookieService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
