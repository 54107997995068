<div class="d-flex"
     [ngClass]="{'disabled':disabled}">
  <input
    type="radio"
    [checked]="checked"
    (change)="checkedEmit($event)"
    id="{{ id }}"
    name="{{ name }}"
    [value]="value"
    [disabled]="disabled"
  />
  <label [for]="id">
    <ng-container *ngIf="!isImg">{{ label ? label : "" }}</ng-container>
    <ng-container *ngIf="isImg"><img src="{{label}}" alt=""></ng-container>
  </label>
</div>
