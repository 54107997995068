import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {filter} from 'rxjs/operators';

import {AuthService} from '../../auth/services/auth.service';
import {TokenService} from '../../../shared/services/token.service';
import {MainService} from '../../main.service';
import {ResizeService} from '../../../shared/services/resize.service';
import {DeviceType} from '../../../shared/models/device-type';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isSearch = false;
  deviceType!: DeviceType;
  hasHome = false;

  constructor(public resize: ResizeService,
              public tokenService: TokenService,
              public auth: AuthService,
              private router: Router,
              public mainService: MainService) {
    this.resize.deviceMode
      .pipe(untilDestroyed(this))
      .subscribe(f => {
        this.deviceType = f;
        if (f !== 'xs' && f !== 'sm') {
          this.isSearch = false;
        }
      });
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd)
      )
      // @ts-ignore
      .subscribe((res: NavigationEnd) => {
        this.hasHome = (res.url === '/home' || res.url === '/');
      });
  }

  toggleMobileSearch(open: boolean): void {
    this.isSearch = open;
  }


}
