import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {TokenService} from '../services/token.service';
import {AuthService} from '../../main/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticatedGuard implements CanActivate {
  constructor(private tokenService: TokenService,
              private router: Router,
              private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.authService.redirectUrl.mainUrl = state.url;
    return this.tokenService.isAuthenticated$.pipe(
      map(isAuth => {
        if (!isAuth) {
          this.router.navigate(['auth']).then();
          return false;
        }

        this.tokenService.getUserType();
        return true;
      })
    );
  }

}
