import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {MentorItemModel} from '../../models/mentor-item.model';

@Component({
  selector: 'app-mentor-cart',
  templateUrl: './mentor-cart.component.html',
  styleUrls: ['./mentor-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MentorCartComponent implements OnInit {
  @Input() mentorItem!: MentorItemModel;

  constructor() {
  }

  ngOnInit(): void {
  }

}
