import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './components/search/search.component';
import {RouterModule} from '@angular/router';
import {ClickOutsideModule} from 'ng-click-outside';
import { MobileSearchComponent } from './components/mobile-search/mobile-search.component';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [SearchComponent, MobileSearchComponent],
    exports: [
        SearchComponent,
        MobileSearchComponent
    ],
  imports: [
    CommonModule,
    RouterModule,
    ClickOutsideModule,
    FormsModule
  ]
})
export class GlobalSearchModule { }
