import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {Observable} from 'rxjs';
import {ResponseModel} from '../../../models/response.model';
import {SearchResultModel} from '../model/search-result.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private apiService: ApiService) {
  }

  getSearchList(payload: string): Observable<ResponseModel<SearchResultModel>> {
    const url = 'global/Search/suggestions';
    const queryParams = {
      phrase: payload
    };
    return this.apiService.get(url, false, queryParams);
  }
}
