import {Pipe, PipeTransform} from '@angular/core';
import {MentorPrefix} from '../../main/modules/global/mentors/enums/mentor-prefix.enum';

@Pipe({
  name: 'mentorPrefix'
})
export class MentorPrefixPipe implements PipeTransform {

  transform(value: MentorPrefix): string {
    let mentorPrefixTitle: string;
    switch (value) {
      case MentorPrefix.PHD :
        mentorPrefixTitle = 'دکتر';
        return mentorPrefixTitle;
      case MentorPrefix.Engineer :
        mentorPrefixTitle = 'مهندس';
        return mentorPrefixTitle;
      case MentorPrefix.Mentor :
        mentorPrefixTitle = 'مدرس';
        return mentorPrefixTitle;
        case MentorPrefix.Group :
        mentorPrefixTitle = 'گروه';
        return mentorPrefixTitle;
      default:
        mentorPrefixTitle = 'مدرس';
        return mentorPrefixTitle;
    }

  }

}
