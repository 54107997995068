export enum ServerErrors {
  NotExpectedException = 0,
  VerificationCodeNotFound = 1,
  EmailAlreadyIsSubscriber = 2,
  ItemNotFound = 3,
  CaptchaCodeIsInvalid = 4,
  CommandValidationError = 5,
  AdminUserNameOrPasswordIsWrong = 6,
  UserNotFound = 7,
  CacheKeyNotFound = 8,
  RestartOperationPlease = 9,
  MobileAlreadyRegistered = 10,
  InvalidPassword = 11
}
