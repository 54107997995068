<div class="custom-modal with-background">
  <div class="modal-header text-center">
    <img class="svg-icon" [src]="svg ? svg : utilityService.EditSVG" alt="">
  </div>
  <div class="modal-body text-center">
    <p class="title text-center">{{modalText}}</p>
  </div>
  <div class="modal-footer">
    <div class="m-auto">
      <button class="btn" [ngClass]="confirmStyle? confirmStyle : 'btn-red' " (click)="onSubmit()">
        {{modalConfirmButton ? modalConfirmButton : 'بله مطمئنم'}}
      </button>
      <button class="btn btn-gray mr-2" (click)="onCancel()">
        {{modalCancelButton ? modalCancelButton : 'بازگشت'}}
      </button>
    </div>
  </div>
</div>
