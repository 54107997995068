import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {MainService} from '../../main/main.service';
import {ResponseModel} from '../models/response.model';
import {Bootstrapper} from '../models/bootstrapper';
import {CategoryService} from '../../main/modules/global/category/service/category.service';

@Injectable({
  providedIn: 'root'
})
export class MainResolverService implements Resolve<ResponseModel<Bootstrapper>> {

  constructor(private mainService: MainService,
              private router: Router,
              private categoryService: CategoryService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ResponseModel<Bootstrapper> | any> {
    await this.mainService.getBootstrapperData()
    .then(response => {
      this.categoryService.categoryList = response.result.categories;
    })
    .catch(() => {
      this.router.navigate(['maintenance']);
    });
  }

}
