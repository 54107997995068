import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'convertPrice'
})

@Injectable({
  providedIn: 'root'
})

export class ConvertPricePipe implements PipeTransform {
  transform(value: number): string {
    if (value !== 0) {
      value = Math.floor(value);
      const body = value.toString().slice(0, -1);
      const valueLength = body.length;
      if (valueLength > 3 && valueLength < 7) {
        return `${body.slice(0, -3)} هزار تومان `;
      } else if (valueLength >= 7) {
        const price = parseInt(body, 10);
        return `${price.toLocaleString()} تومان`;
      } else {
        const price = parseInt(body, 10);
        return `${price.toLocaleString()} تومان `;
      }
    } else {
      return `رایگان`;
    }
  }
}
