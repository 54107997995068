<div class="mobile_search">
  <div class="mobile_search_wrapper">
    <div class="mobile_search_box">

      <input (click)="onClickedInput()"
             (input)="globalSearch($event)"
             placeholder="جستجو مدرس، درس"

             type="text"
      >
      <i (click)="closeEvent()" class="fas fa-times"></i>
    </div>

  </div>

  <div *ngIf="isSearch" class="mobile_search_list">
    <ng-container *ngIf="resultSearch.webinars.length ||
                         resultSearch.mentors.length"
    >
      <div class="mobile_search_container container">
        <ng-container *ngIf="resultSearch.webinars.length">
          <div class="mobile_search_list_item webinar">
            <h6>دوره ها</h6>
            <div *ngFor="let item of resultSearch.webinars">
              <a (click)="closeEvent()"
                 [routerLink]="['/webinar',item.id,convertUrl.transform(item.title)]"
                 [title]="item.title"
                 class="align-items-start"
              >
                <img [src]="item.wallpaperUrl" alt="{{item.title}}"/>
                <strong>{{item.title}}</strong>
                <span>{{item.mentor}}</span>
              </a>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="resultSearch.mentors.length">
          <div class="mobile_search_list_item mentor">
            <h6>اساتید</h6>
            <div *ngFor="let item of resultSearch.mentors">
              <a (click)="closeEvent()"
                 [queryParams]="{mentorCode: item.mentorCode}"
                 [routerLink]="['/mentors',convertUrl.transform(item.fullName)]"
                 [title]="item.fullName"
              >
                <img [src]="item.profileImageUrl" alt="{{item.fullName}}"/>
                <strong>{{item.fullName}}</strong>
                <span>{{item.title}}</span>
              </a>
            </div>
          </div>
        </ng-container>

      </div>

    </ng-container>

    <ng-container *ngIf="resultSearch.webinars.length > 2 || resultSearch.mentors.length > 2">
      <a (click)="closeEvent()"
         [queryParams]="{phrase: phrase}"
         [routerLink]="['/search-result']" class="show-more">
        نمایش همه
        <i class="far fa-chevron-left"></i>
      </a>
    </ng-container>

    <ng-container *ngIf="!resultSearch.webinars.length &&
                         !resultSearch.mentors.length"
    >
      <div class="no_result">
        <span>نتیجه ای یافت نشد!</span>
      </div>
    </ng-container>

  </div>
</div>
