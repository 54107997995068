export enum AppNotifications {
  UnknownError = 'خطایی در شبکه رخ داده است',
  NotExpectedException = 'خطایی در شبکه رخ داده است',
  VerificationCodeNotFound = 'کد وارد شده صحیح نمی‌باشد.',
  EmailAlreadyIsSubscriber = 'با این ایمیل در حال حاضر اشتراک دارید.',
  ItemNotFound = 'صفحه مورد نظر یافت نشد',
  CaptchaCodeIsInvalid = 'کد کپچا نامعتبر است. برای دریافت کپچا جدید بر روی آن کلیک کنید.',
  CommandValidationError = 'CommandValidationError',
  LoginAgain = 'دوباره وارد شوید',
  UserNotFound = 'کاربر مورد نظر یافت نشد.',
  CacheKeyNotFound = 'اطلاعاتی یافت نشد.',
  RestartOperationPlease = 'لطفاْ عملیات را از سربگیرید.',
  MobileAlreadyRegistered = 'شماره موبایل قبلاْ در سیستم ثبت شده است.',
  InvalidPassword = 'رمزعبور شما اشتباه است.'
}
