<header [ngClass]="{'home-header': hasHome}" class="header">
  <div class="container">
    <div class="row">
      <div class="col-xl-5 col-lg-4 col-md-5 col-4 order-md-2">
        <app-menu></app-menu>
      </div>
      <div class="col-md-2 col-4 order-md-1">
        <div class="header_logo_wrapper">
          <a routerLink="/" routerLinkActive="active">
            <img *ngIf="!hasHome" alt="آکادمی ایده پردازان" src="assets/images/logo.png">
            <img *ngIf="hasHome" alt="آکادمی ایده پردازان" src="assets/images/white-logo.png">
          </a>
        </div>
      </div>
      <div class="col-xl-5 col-lg-6 col-md-5 col-4 order-md-3 pr-0">
        <div class="header_action_col">
          <app-search *ngIf="deviceType !== 'xs' && deviceType !== 'sm' ">
          </app-search>

          <app-mobile-search (isClose)="toggleMobileSearch($event)"
                             *ngIf="(deviceType === 'xs' || deviceType ==='sm') && isSearch">
          </app-mobile-search>

          <div (click)="toggleMobileSearch(true)" *ngIf="(deviceType === 'xs' || deviceType ==='sm') && !isSearch"
               class="header_search_mobile">
            <i class="fas fa-search"></i>
          </div>
          <div *ngIf="deviceType === 'xs' || deviceType ==='sm'"
               [ngClass]="{'circle':isSearch}"
               class="mobile_search_box"></div>

          <div *ngIf="deviceType !== 'xs' && deviceType !== 'sm'" [ngClass]="!(tokenService.isAuthenticated$ | async) ? 'need_login': '' "
               class="header_login">

            <button *ngIf="!(tokenService.isAuthenticated$ | async)" [routerLinkActive]="['active']"
                    [routerLink]="['/auth/login']"
                    class="btn btn-login">ورود/ثبت نام
            </button>

            <div *ngIf="(tokenService.isAuthenticated$ | async)" class="header_user" ngbDropdown>
              <button class="header_user_btn" id="dropdownBasic1" ngbDropdownToggle>
                <i class="fas fa-user"></i>
                <i class="far fa-angle-down"></i>
              </button>
              <div aria-labelledby="dropdownBasic1" class="header_user_menu" ngbDropdownMenu>
                <!-- <a routerLink="user/profile" routerLinkActive="active" class="header_user_menu_item" ngbDropdownItem>-->
                <a class="header_user_menu_item" ngbDropdownItem routerLink="user/purchased-products"
                   routerLinkActive="active">
                  <i class="fas fa-user"></i>
                  <div *ngIf="{userData:mainService.userData$ | async} as O" class="header_user_menu_item_details">
                    <ng-container *ngIf="O.userData !== null">
                      <span *ngIf="O.userData.fullName">{{O.userData.fullName}}</span>
                      <span *ngIf="!O.userData.fullName">بدون نام</span>
                    </ng-container>

                    <div class="header_user_menu_item_details_sub">
                      <span>حساب کاربری من</span>
                      <i class="far fa-angle-left"></i>
                    </div>
                  </div>
                </a>
                <a (click)="auth.logOut()" class="header_user_menu_item dropdown-logout" ngbDropdownItem>
                  <i class="fas fa-sign-out-alt"></i>
                  <div class="header_user_menu_item_details">
                    <span>خروج از حساب کاربری</span>
                  </div>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</header>
