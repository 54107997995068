<div class="custom-modal with-background">
  <div class="modal-header">
    <h5>{{title}}</h5>
  </div>
  <form (ngSubmit)="setUserPasswordHandler()" [formGroup]="setUserPasswordForm" class="form-wrapper">
    <div class="modal-body">
      <p>{{description}}</p>

      <div class="form-field">
        <label>
          <span>رمزعبور</span> <span class="required">*</span>
          <input
            autocomplete="off"
            class="form-control"
            formControlName="password"
            placeholder="رمز خود را وارد کنید"
            type="password"
          />
          <div class="error-place">
            <div
              *ngIf="((setUserPasswordForm.controls['password'].dirty || setUserPasswordForm.controls['password'].touched))">
              <p *ngIf="setUserPasswordForm.hasError('required', 'password')" class="errorMessage">
                <i class="far fa-exclamation-circle"></i>
                پر کردن این فیلد الزامیست.
              </p>
              <p *ngIf="setUserPasswordForm.hasError('minlength', 'password')" class="errorMessage">
                <i class="far fa-exclamation-circle"></i>
                رمزعبور شما باید بیشتر از 6 کاراکتر باشد.
              </p>
              <p *ngIf="setUserPasswordForm.hasError('pattern', 'password')" class="errorMessage">
                <i class="far fa-exclamation-circle"></i>
                رمز خود معتبر نیست.
              </p>
            </div>
          </div>
        </label>
      </div>

      <div class="form-field">
        <label>
          <span>تکرار رمزعبور</span> <span class="required">*</span>
          <input
            autocomplete="off"
            class="form-control"
            formControlName="confirmPassword"
            placeholder="رمز خود را تکرار کنید"
            type="password"
          >
          <div class="error-place">
            <div
              *ngIf="((setUserPasswordForm.controls['confirmPassword'].dirty || setUserPasswordForm.controls['confirmPassword'].touched))">
              <p *ngIf="setUserPasswordForm.hasError('required', 'confirmPassword')" class="errorMessage">
                <i class="far fa-exclamation-circle"></i>
                پر کردن این فیلد الزامیست.
              </p>
              <p *ngIf="setUserPasswordForm.hasError('mismatchPassword', 'confirmPassword')" class="errorMessage">
                <i class="far fa-exclamation-circle"></i>
                رمزعبور و تکرار با یکدیگر برابر نیستند.
              </p>
            </div>
          </div>
        </label>
      </div>

    </div>

    <div class="modal-footer">
      <div class="m-auto">
        <button [disabled]="setUserPasswordForm.status==='INVALID'"
                class="btn btn-custom-orange"
                type="submit">
          ثبت رمز عبور
        </button>

        <button (click)="onCancel()" type="button" class="btn btn-gray mr-2">
          بعداً انجام می‌دهم!
        </button>
      </div>

    </div>

  </form>
</div>
