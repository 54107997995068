import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import jwt_decode from 'jwt-decode';

import {CookieService} from 'ngx-cookie-service';
import {LoginResultModel} from '../../main/auth/models/login-result.model';
import {RoleTypeEnum} from '../enum/role-type.enum';

@Injectable({
  providedIn: 'root'
})

export class TokenService {

  private _ACCESS_TOKEN = 'access_token';
  private _REFRESH_TOKEN = 'refresh_token';
  private isAuthenticated!: BehaviorSubject<boolean>;
  isAuthenticated$: Observable<boolean>;
  roleTypeId!: RoleTypeEnum;
  roleType = RoleTypeEnum;

  constructor(private cookieService: CookieService) {
    this.isAuthenticated = new BehaviorSubject<boolean>(this.hasAccessToken() && !this.isTokenExpired());
    this.isAuthenticated$ = this.isAuthenticated.asObservable();
  }

  setTokenItems(loginResult: LoginResultModel): void {
    this.cookieService.set(this._ACCESS_TOKEN, loginResult.accessToken, {path: '/'});
    this.cookieService.set(this._REFRESH_TOKEN, loginResult.refreshToken, {path: '/'});
  }

  getAccessToken(): string {
    return this.cookieService.get(this._ACCESS_TOKEN);
  }

  getRefreshToken(): string {
    return this.cookieService.get(this._REFRESH_TOKEN);
  }

  removeAllToken(): void {
    this.cookieService.deleteAll('/');
  }

  hasAccessToken(): boolean {
    const hasAccessToken = this.getAccessToken();
    return hasAccessToken !== '';
  }

  /*  secondsToExpire(): number {
      const accessToken = this.getAccessToken();
      const decoded: any = jwt_decode(accessToken);

      const exp = Number(decoded.exp);
      const strNow = new Date().getTime().toString();
      const now = Number((strNow.substring(0, strNow.length - 3)));

      return exp - now;
    }*/

  isTokenExpired(): boolean {
    if (!this.hasAccessToken()) {
      throw new Error('access token not found');
    }

    const exp = this.getAccessTokenExpireTime();
    const strNow = new Date().getTime().toString();
    const now = Number(strNow.substring(0, strNow.length - 3));
    return now > exp;
  }

  getAccessTokenExpireTime(): number {
    const accessToken = this.getAccessToken();
    const decoded: any = jwt_decode(accessToken);
    const exp = Number(decoded.exp);
    return exp;
  }

  setIsAuthenticated(isAuthenticated: boolean): void {
    this.isAuthenticated.next(isAuthenticated);
  }

  getUserType(): void {
    if (!this.hasAccessToken()) {
      return;
    }
    const token = this.getAccessToken();
    const jwtToken: any = jwt_decode(token);
    const roleValue: string | Array<string> = jwtToken.hasOwnProperty('http://schemas.microsoft.com/ws/2008/06/identity/claims/role') ? jwtToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] : 'User';
    if (roleValue.constructor === String) {
      this.roleTypeId = (roleValue === 'Mentor') ? this.roleType.Mentor : this.roleType.User;
    }
    if (Array.isArray(roleValue)) {
      roleValue.find(item => item === 'Mentor');
      this.roleTypeId = roleValue.find(item => item === 'Mentor') ? this.roleType.Mentor : this.roleType.User;
    }
  }
}
