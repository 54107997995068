<ng-container *ngIf="{ deviceMode: resize.deviceMode | async , dataTable: dataTable$ | async} as O">
  <div class="grid-table">

    <!-- if table is empty-->
    <div *ngIf="O.dataTable.items.length === 0" class="alert-wrap info">
      <i class="fas fa-info-circle"></i>
      رکوردی یافت نشد!
    </div>

    <ng-container
      *ngIf="O.dataTable.items.length && (O.deviceMode !== 'md' && O.deviceMode !=='xs' && O.deviceMode !== 'sm')">
      <div class="scrollbar-style">
        <ul class="scrollbar-style-inner">

          <!-- Table header-->
          <li class="header-item">
            <div *ngFor="let headerItem of ObjectKey(tableConfig.headings); let i = index;"
                 [ngClass]="tableConfig.gridClass? tableConfig!.gridClass[i] : '' "
                 [style]="tableConfig!.cellStyle ? {flexBasis: tableConfig.cellStyle[i] , maxWidth : tableConfig.cellStyle[i]} : ''"
            >
              {{tableConfig.headings[headerItem]}}
            </div>
            <div *ngIf="tableConfig.actions" class="action-icon"></div>
          </li>

          <!-- Table body-->
          <li *ngFor="let dataRow of O.dataTable.items; " class="body-item">
            <div *ngFor="let headerItem of ObjectKey(tableConfig.headings); let i = index;"
                 [ngClass]="(dataRow[headerItem+'CssSelector'] && tableConfig!.gridClass) ? (dataRow[headerItem+'CssSelector'](dataRow[headerItem]) +' '+ tableConfig.gridClass[i]) :
                             dataRow[headerItem+'CssSelector'] ? dataRow[headerItem+'CssSelector'](dataRow[headerItem]) :
                            tableConfig!.gridClass ? tableConfig.gridClass[i] : '' "
                 [style]="tableConfig!.cellStyle ? {flexBasis: tableConfig.cellStyle[i] , maxWidth : tableConfig.cellStyle[i]} : ''"
            >
              {{dataRow[headerItem]}}
            </div>

            <ng-container *ngIf="tableConfig.actions">
              <div [ngClass]="tableConfig.gridClass? tableConfig.gridClass[tableConfig.gridClass.length - 1] : '' "
                   [style]="tableConfig!.cellStyle ? {flexBasis: tableConfig.cellStyle[tableConfig.cellStyle.length - 1] , maxWidth : tableConfig.cellStyle[tableConfig.cellStyle.length - 1]} : ''"
                   class="action-icon"
              >
                <span *ngFor="let rowAction of ObjectKey(tableConfig.actions)" class="{{rowAction}}">
                <i (click)="fireAction(rowAction, dataRow)" class="{{tableConfig.actions[rowAction].icon}}"></i>
              </span>
              </div>
            </ng-container>
          </li>

        </ul>
      </div>
    </ng-container>

    <ng-container
      *ngIf="O.dataTable.items.length && (O.deviceMode === 'md' || O.deviceMode ==='xs' || O.deviceMode === 'sm')">
      <div *ngFor="let dataRow of O.dataTable.items;" class="table-mobile-mode">

        <div *ngFor="let headerItem of ObjectKey(tableConfig.headings)" class="row">
          <div class="header-item">
            {{tableConfig.headings[headerItem]}}
          </div>
          <div
            [ngClass]="dataRow[headerItem+'CssSelector'] ? dataRow[headerItem+'CssSelector'](dataRow[headerItem])  : '' "
            class="body-item">
            {{dataRow[headerItem]}}
          </div>
        </div>

        <ng-container *ngIf="tableConfig.actions">
          <div *ngFor="let rowAction of ObjectKey(tableConfig.actions)" class="action-icon">
            <span (click)="fireAction(rowAction, dataRow)" class="action-name">
              {{rowAction | actionNameTranslate}}
              <i class="{{tableConfig.actions[rowAction].icon}}"></i>
            </span>
          </div>
        </ng-container>

      </div>
    </ng-container>

    <ng-container *ngIf="O.dataTable">
      <div *ngIf="O.dataTable!.totalItemsCount > 0 ">
        <div class="pagination">
          <ngb-pagination (pageChange)="pageChanged($event)"
                          *ngIf="tableConfig.needPagination && O.dataTable.items.length > 0"
                          [collectionSize]="O.dataTable.totalItemsCount"
                          [pageSize]="O.dataTable.pageSize"
                          [page]="O.dataTable.pageNumber">
            <ng-template ngbPaginationPrevious><i class="fal fa-angle-right"></i></ng-template>
            <ng-template ngbPaginationNext><i class="fal fa-angle-left"></i></ng-template>
            <ng-template let-page ngbPaginationNumber>{{ page }}</ng-template>
          </ngb-pagination>
        </div>
      </div>
    </ng-container>

  </div>
</ng-container>
