import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {GlobalManipulationService} from './global-manipulation.service';

@Injectable({
  providedIn: 'root'
})

export class LoadingService {

  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loading.asObservable();

  constructor(private global: GlobalManipulationService) {
  }

  setLoadingStatus(status: boolean): void {
    this.global.toggleOverflow(status);
    return this.loading.next(status);
  }
}
