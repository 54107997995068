import {Injectable} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';
import {UtilityService} from './utility.service';

let thisUtilityService;

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor(private utilityService: UtilityService) {
    thisUtilityService = this.utilityService;
  }

  checkMatchPassword(passwordControlName: string, newPasswordControlName: string): ValidatorFn {
    return (currentControl: any): { [p: string]: any } | null => {
      let isValid;
      const parent = currentControl.parent;
      if (parent && parent.controls) {
        const pass = parent.controls[passwordControlName];
        const confirmPass = parent.controls[newPasswordControlName];
        isValid = pass.value === confirmPass.value;
      }
      return isValid ? null : {mismatchPassword: {valid: false}};
    };
  }

  required(skipWhiteSpaceValidation?: boolean): ValidatorFn {
    return (currentControl: AbstractControl): { [p: string]: any } | null => {
      let isValid;
      if (currentControl.value !== null && currentControl.value !== undefined) {
        if (currentControl.value === false || currentControl.value === true) {
          isValid = true;
        } else {
          if (skipWhiteSpaceValidation === true) {
            isValid = String(currentControl.value).length > 0;
          } else {
            isValid = String(currentControl.value).trim().length > 0;
          }
        }

      } else {
        isValid = false;
      }
      if (!isValid) {
        return {
          required: {
            valid: false
          }
        };
      } else {
        return null;
      }
    };
  }

  password(formControl: FormControl): { password: { valid: boolean; minimumChar: boolean } } | null {
    // const passwordRegEx: RegExp = new RegExp('^(?=^.{6,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s)[0-9a-zA-Z!@#$%^&*()]*$');
    const passwordRegEx: RegExp = new RegExp('^((?!.*[\\s]).{6,})');
    let isValid = false;
    let minimumChar = false;
    if (formControl.value.length >= 6) {
      minimumChar = true;
    }
    if (minimumChar && passwordRegEx.test(formControl.value)) {
      isValid = true;
    }
    if (!isValid) {
      return {
        password: {
          minimumChar,
          valid: false,
        }
      };
    } else {
      return null;
    }
  }


}
