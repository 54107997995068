import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

@Pipe({
  name: 'actionNameTranslate'
})

export class TableActionTranslatePipe implements PipeTransform {
  transform(value: any): string {
    switch (value) {
      case 'show':
        return 'مشاهده';
      case 'view':
        return 'مشاهده';
      case 'delete':
        return 'حذف';
      default:
        return '';
    }
  }

}
