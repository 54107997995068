import {Injectable} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';

import {HandleErrorService} from './handle-error.service';
import {TokenService} from './token.service';
import {LoadingService} from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private handleError: HandleErrorService,
              private tokenService: TokenService,
              private router: Router,
              private loadingService: LoadingService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLoading = req.headers.getAll('Need-Loading');
    return next.handle(req)
    .pipe(
      map((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.Sent) {
          const requestMethod: string = req.method;
          if (requestMethod) {
            if (isLoading && isLoading[0] === 'true') {
              this.loadingService.setLoadingStatus(true);
            }
            // console.log(req.headers.keys());
            // console.log(req.headers.getAll('Need-Loading'));
          }
        }

        if (event instanceof HttpResponse) {
          // if response come
          this.loadingService.setLoadingStatus(false);

          if (event.status === 200 && !event.body.success && event.body.type !== 'application/pdf') {
            this.handleError.handle(event.body.errorCode);
            throw new Error(event.body.errorCode);
          }
        } else {
          // TODO Show loading in first load of App
          /*if (isLoading && isLoading[0] === 'true') {
            this.loadingService.setLoadingStatus(true);
          }*/
        }
        return event;
      }),
      catchError((err, caught) => {
        if (err.status && (err.status === 401 || err.status === 403)) {
          this.tokenService.setIsAuthenticated(false);
          // TODO: Display suitable message!
        }
        if (err.status && (err.status !== 400 && err.status !== 404)) {
          this.handleError.handle(err);
        }
        if (err.status && err.status === 404) {
          this.router.navigate(['/404']).then();
        }

        this.loadingService.setLoadingStatus(false);
        return throwError(err);
      })
    );
  }
}
