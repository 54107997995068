<div class="progress-loader" *ngIf="(loadingService.loading$ | async)">
  <div class="loading-spinner">
    <span class="whirly-loader"></span>
  </div>
</div>

<app-header></app-header>
<main>
  <router-outlet></router-outlet>
</main>
<app-footer></app-footer>



