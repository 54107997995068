import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-increase-decrease',
  templateUrl: './increase-decrease.component.html',
  styleUrls: ['./increase-decrease.component.scss']
})
export class IncreaseDecreaseComponent implements OnInit, AfterContentChecked {
  @Input() defaultValue = 0;
  @Input() min = 0;
  @Input() max = 10;
  @Input() step = 1;

  currentValue!: number;
  plusBtnDisabled = false;
  minusBtnDisabled = false;

  @Output() changeValue: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit(): void {
    this.initHandler();
  }

  ngAfterContentChecked(): void {
    this.initHandler();
  }

  initHandler(): void {
    if (this.defaultValue >= this.max) {
      this.defaultValue = this.max;
      this.plusBtnDisabled = true;
      this.minusBtnDisabled = false;
    }
    if (this.defaultValue <= this.min) {
      this.defaultValue = this.min;
      this.minusBtnDisabled = true;
      this.plusBtnDisabled = false;
    }
    if (this.step === 0) {
      this.step = 1;
    }
    this.currentValue = this.defaultValue;
  }

  quantityHandler(type: boolean): void {
    if (type) {
      this.minusBtnDisabled = false;
      if (this.currentValue + this.step > this.max) {
        this.plusBtnDisabled = true;
      } else if (this.currentValue + this.step === this.max) {
        this.plusBtnDisabled = true;
        this.currentValue = this.currentValue + this.step;
      } else {
        this.currentValue = this.currentValue + this.step;
      }
    } else {
      this.plusBtnDisabled = false;
      if (this.currentValue - this.step < this.min) {
        this.minusBtnDisabled = true;
      } else if (this.currentValue - this.step === this.min) {
        this.minusBtnDisabled = true;
        this.currentValue = this.currentValue - this.step;
      } else {
        this.currentValue = this.currentValue - this.step;
      }
    }
    this.changeValue.emit(this.currentValue);
  }

  // inputChangeHandler(event: any): void {
  //   const value = parseInt(event.target.value, 10);
  //   if (isNaN(value) || value > this.max) {
  //     this.minusBtnDisabled = false;
  //     this.plusBtnDisabled = true;
  //     this.currentValue = this.max;
  //   } else if (value < this.min) {
  //     this.minusBtnDisabled = true;
  //     this.plusBtnDisabled = false;
  //     this.currentValue = this.min;
  //   } else {
  //     this.minusBtnDisabled = false;
  //     this.plusBtnDisabled = false;
  //     this.currentValue = value;
  //   }
  //   this.changeValue.emit(this.currentValue);
  // }
}
