import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ConvertPriceWithComma'
})

@Injectable({
  providedIn: 'root'
})

export class ConvertPriceWithCommaPipe implements PipeTransform {

  transform(value: number | null, ...args: unknown[]): string {
    if (value && value !== 0) {
      value = Math.floor(value);
      const price = value.toString().slice(0, -1);
      return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} تومان`;
    } else {
     return (value === null) ?  'بزودی ...' : 'رایگان';
    }
  }
}
