import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidatorsService} from '../../../../shared/services/validators.service';
import {RegexPatternService} from '../../../../shared/services/regex-pattern.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {AuthService} from '../../services/auth.service';
import {ToastifyService} from '../../../../shared/services/toastify.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {json} from 'express';

@UntilDestroy()

@Component({
  selector: 'app-modal-get-password',
  templateUrl: './modal-get-password.component.html',
  styleUrls: ['./modal-get-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ModalGetPasswordComponent implements OnInit {
  setUserPasswordForm!: FormGroup;
  title = 'ایجاد رمزعبور';
  description = 'کاربر گرامی، جهت سهولت ورود به سامانه، لطفاً برای حساب کاربری خود، رمزعبور ایجاد نمایید.';

  constructor(private formBuilder: FormBuilder,
              private validatorsService: ValidatorsService,
              private regexService: RegexPatternService,
              private authService: AuthService,
              private toastifyService: ToastifyService,
              private ngbActiveModal: NgbActiveModal) {
    this.setUserPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.pattern(this.regexService.passwordPattern)]],
      confirmPassword: ['', [Validators.required, this.validatorsService.checkMatchPassword('password', 'confirmPassword')]]
    });
  }

  ngOnInit(): void {
  }

  get passwordControlsErrors(): any {
    return this.setUserPasswordForm.controls.password.errors;
  }

  setUserPasswordHandler(): void {
    const payload = JSON.stringify(this.setUserPasswordForm.value.password);
    this.authService.setUserPasswordHandler(payload)
    .pipe(untilDestroyed(this))
    .subscribe(res => {
        this.ngbActiveModal.close(false);
        this.toastifyService.success('رمزعبور با موفقیت ثبت شد.');
      },
      err => {
        this.ngbActiveModal.close(false);
        this.toastifyService.error('با خطا مواجه شدید. مجدداً تلاش کنید!');
        this.setUserPasswordForm.reset();
      });
  }

  onCancel(): void {
    this.ngbActiveModal.close(false);
  }

}
