import {Injectable} from '@angular/core';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private $modalOptions: NgbModalOptions = {
    centered: true,
    windowClass: 'm-modal',
    scrollable: true
  };

  public modalOptions(size?: string, scrollable?: boolean): any {
    if (size) {
      return {
        centered: true,
        scrollable,
        size
      };
    } else {
      return this.$modalOptions;
    }
  }

  get hostName(): string {
    return this._hostName;
  }

  set hostName(value: string) {
    this._hostName = value;
  }

  // get config(): any {
  //   return this._config;
  // }
  //
  // set config(value: any) {
  //   this._config = value;
  // }

  get baseUrl(): string {
    return this._baseUrl;
  }

  set baseUrl(value: string) {
    this._baseUrl = value;
  }

  // private _config!: BaseConfig;
  private _baseUrl!: string;
  private _hostName!: string;


  constructor() {
    // this.config = data;r
    // this.baseUrl = 'https://localhost:44345';
    // this.baseUrl = 'https://localhost:5001';
    // this.baseUrl = 'http://192.168.2.226:8023';
    // this.baseUrl = 'http://192.168.2.193:1010';
    this.baseUrl = 'https://www.systemapi.ipdemy.ir';
    // this.baseUrl = 'https://test1.systemapi.ipdemy.ir';
    this.hostName = window.location.origin;
  }
}
