import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'convertDate'
})
export class ConvertDatePipe implements PipeTransform {

  transform(value: number, pattern?: string): string {
    const sliceMonth = value.toString().slice(4, 6);
    let monthName = '';
    switch (sliceMonth) {
      case '01':
        monthName = 'فروردین';
        break;
      case '02':
        monthName = 'اردیبهشت';
        break;
      case '03':
        monthName = 'خرداد';
        break;
      case '04':
        monthName = 'تیر';
        break;
      case '05':
        monthName = 'مرداد';
        break;
      case '06':
        monthName = 'شهریور';
        break;
      case '07':
        monthName = 'مهر';
        break;
      case '08':
        monthName = 'آبان';
        break;
      case '09':
        monthName = 'آذر';
        break;
      case '10':
        monthName = 'دی';
        break;
      case '11':
        monthName = 'بهمن';
        break;
      case '12':
        monthName = 'اسفند';
        break;
    }

    if (value.toString().length > 8) {
      switch (pattern) {
        case 'fromTheClock':
          return `${value.toString().slice(6, 8)} ${monthName} ${value.toString().slice(0, 4)} از ساعت ${value.toString().slice(8, 10)}:${value.toString().slice(10, 12)}`;
     case 'justTime':
          return ` ساعت ${value.toString().slice(8, 10)}:${value.toString().slice(10, 12)}`;
        default:
          return `${value.toString().slice(6, 8)} ${monthName} ماه ${value.toString().slice(0, 4)}`;
      }
    } else {
      return `${value.toString().slice(6, 8)} ${monthName} ${value.toString().slice(0, 4)}`;
    }
  }

}
