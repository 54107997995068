<div *ngIf="mentorItem" class="mentor-item">
  <a
    [routerLink]="['../mentors/',mentorItem.mentorCode, mentorItem.fullName | convertUrl]"
    routerLinkActive="active">
    <div class="gradiant-row"></div>
    <div class="mentor-details">
      <div class="mentor-img-wrapper">
        <div class="mentor-img-item">
          <img [src]="mentorItem.profileImageUrl" alt="">
        </div>
      </div>
      <h6 class="mentor-name">{{mentorItem.fullName}}</h6>
      <span class="mentor-position">{{mentorItem.title}}</span>
    </div>
  </a>
</div>
