import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

import {ApiService} from '../shared/services/api.service';
import {CookieService} from 'ngx-cookie-service';
import {UserData} from '../shared/models/user-data';
import {ResponseModel} from '../shared/models/response.model';
import {Bootstrapper} from '../shared/models/bootstrapper';

@Injectable({
  providedIn: 'root'
})

export class MainService {
  private userData: ReplaySubject<UserData> = new ReplaySubject<UserData>(1);
  userData$: Observable<UserData> = this.userData.asObservable();
  private _SHOW_PASS_MODAL = 'show_password_modal';

  constructor(private apiService: ApiService,
              private cookieService: CookieService) {
  }

  setUserPasswordModal(state: boolean): void {
    this.cookieService.set(this._SHOW_PASS_MODAL, String(state));
  }

  getUserPasswordModal(): string {
    return this.cookieService.get(this._SHOW_PASS_MODAL);
  }

  isUserPasswordModalShowed(): boolean {
    const hasAccessToken = this.getUserPasswordModal();
    return hasAccessToken !== '' && hasAccessToken === 'true';
  }

  getBootstrapperData(): Promise<ResponseModel<Bootstrapper>> {
    const url = 'global/Bootstrapper';
    return this.apiService.get(url, false, {}, true).toPromise<any>();
  }

  postNewsletter(payload: string): Observable<ResponseModel<boolean>> {
    const url = 'global/Newsletter';
    return this.apiService.post(url, payload, false);
  }

  getUserData(): Observable<ResponseModel<UserData>> {
    const url = 'secured/user';
    return this.apiService.get(url, true);
  }

  setUserData(obj: UserData): void {
    return this.userData.next(obj);
  }

}
