import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalManipulationService {
  isOverflow = false;

  constructor() {
  }

  toggleOverflow(value: boolean): void {
    this.isOverflow = value;
    const element = document.getElementById('body')!;
    if (this.isOverflow) {
      element.classList.add('overflow-hidden');
    } else {
      element.classList.remove('overflow-hidden');
    }
  }
}
