<div (clickOutside)="onClickedOutside($event)" class="search_wrapper {{mode}}">
  <div
    [ngClass]="{
      'search_header': mode==='header',
      'search_banner': mode==='banner',
      'isOpen': isOpen,
      'home':hasHome}"
  >

    <i (click)="openHandler()" class="fas fa-search"></i>
    <input #searchInput (click)="onClickedInput()"
           (input)="globalSearch($event)"
           [(ngModel)]="searchBox"
           [placeholder]="mode === 'banner' ?'چی دوست داری؟ بنویس برام...' :'جستجو...'"
           type="text">
  </div>

  <div *ngIf="isSearch && isOpen" class="search_list">

    <ng-container *ngIf="resultSearch.webinars.length ||
                         resultSearch.mentors.length"
    >
      <ng-container>
        <div class="search_list_item" [ngClass]="{'hide': !resultSearch.webinars.length}">
          <h6>دوره ها</h6>
          <div class="search_list_webinar">

            <div *ngFor="let item of resultSearch.webinars">
              <a (click)="closeSearchBox()"
                 [routerLink]="['/webinar',item.id,convertUrl.transform(item.title)]"
                 [title]="item.title"
              >
                <img [src]="item.wallpaperUrl" alt="{{item.title}}"/>
                <p>{{item.title}}</p>
                <span>{{item.mentor}}</span>
              </a>
            </div>

          </div>
        </div>
      </ng-container>

      <ng-container>
        <div class="search_list_item" [ngClass]="{'hide': !resultSearch.mentors.length}">
          <h6 class="pr-3">اساتید</h6>
          <div *ngIf="resultSearch.mentors.length"
               class="search_list_mentor">
            <div *ngFor="let item of resultSearch.mentors">
              <a (click)="closeSearchBox()" [queryParams]="{mentorCode: item.mentorCode}"
                 [routerLink]="['/mentors',convertUrl.transform(item.fullName)]"
                 [title]="item.fullName"
              >
                <img [src]="item.profileImageUrl" alt="{{item.fullName}}"/>
                <strong>{{item.fullName}}</strong>
                <span>{{item.title}}</span>
              </a>
            </div>
          </div>
        </div>
      </ng-container>


    </ng-container>

    <ng-container *ngIf="resultSearch.webinars.length > 2 || resultSearch.mentors.length > 2">
      <a [routerLink]="['/search-result']"
         [queryParams]="{phrase: phrase}" class="show-more">
        نمایش همه
        <i class="far fa-chevron-left"></i>
      </a>
    </ng-container>

    <ng-container *ngIf="!resultSearch.webinars.length &&
                         !resultSearch.mentors.length"
    >
      <div class="no_result">
        <span class="">نتیجه ای یافت نشد!</span>
      </div>
    </ng-container>

  </div>

</div>
