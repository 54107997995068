import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {MainCategoryFilterItem} from '../models/main-category-filter-item';
import {ApiService} from '../../../../../shared/services/api.service';
import {MainCategoryModel} from '../models/main-category.model';
import {ResponseModel} from '../../../../../shared/models/response.model';
import {PagedDataModel} from '../../../../../shared/models/paged-data.model';
import {WebinarModel} from '../../../../../shared/modules/product-card/models/webinar.model';
import {AddAsFavoriteModel} from '../../../../../shared/modules/product-card/models/add-as-favorite.model';
import {TokenService} from '../../../../../shared/services/token.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private _CATEGORY_LIST!: MainCategoryModel[];
  private _CATEGORY_ITEM!: MainCategoryModel;
  private isAuth!: boolean;
  mainCategoryId!: number;
  isInitialCallAPI = true;

  private filterList!: BehaviorSubject<MainCategoryFilterItem>;
  filterItems$: Observable<MainCategoryFilterItem>;

  private webinarItemsSubject!: BehaviorSubject<PagedDataModel<WebinarModel>>;
  webinarItems$: Observable<PagedDataModel<WebinarModel>>;

  constructor(private apiService: ApiService,
              private tokenService: TokenService) {

    this.tokenService.isAuthenticated$
      .subscribe(res => {
          this.isAuth = res;
        }
      );

    // @ts-ignore
    this.filterList = new BehaviorSubject<MainCategoryFilterItem>(undefined);
    this.filterItems$ = this.filterList.asObservable();

    // @ts-ignore
    this.webinarItemsSubject = new BehaviorSubject<PagedDataModel<WebinarModel>>(undefined);
    this.webinarItems$ = this.webinarItemsSubject.asObservable();
  }

  get categoryList(): MainCategoryModel[] {
    return this._CATEGORY_LIST;
  }

  set categoryList(value: MainCategoryModel[]) {
    this._CATEGORY_LIST = value;
  }

  get categorySelectedItem(): MainCategoryModel {
    return this._CATEGORY_ITEM;
  }

  set categorySelectedItem(value: MainCategoryModel) {
    this._CATEGORY_ITEM = value;
  }

  getFilterList(): MainCategoryFilterItem {
    return this.filterList.value;
  }

  getMainCategory(queryString?: string, needLoading= false): Observable<ResponseModel<PagedDataModel<WebinarModel>>> {
    const url = this.isAuth ? `secured/webinar?${queryString}` : `global/Webinar?${queryString}`;
    return this.apiService.get(url, this.isAuth, {}, needLoading);
  }

  addWebinarToFavorites(payload: AddAsFavoriteModel): Observable<ResponseModel<boolean>> {
    const url = 'secured/favourite';
    return this.apiService.patch(url, payload, true);
  }

  setFilterItem(mainCategoryFilterItem: MainCategoryFilterItem): void {
    return this.filterList.next(mainCategoryFilterItem);
  }

  setWebinarItems(webinarListResponse: PagedDataModel<WebinarModel>): void {
    return this.webinarItemsSubject.next(webinarListResponse);
  }

}
