import {Injectable} from '@angular/core';
import * as MJ from 'jalali-moment';
import {BehaviorSubject, Observable} from 'rxjs';

import {BankEnum} from '../enum/bank.enum';
import {Bank} from '../models/bank';

@Injectable({
  providedIn: 'root'
})

export class UtilityService {

  constructor() {
  }

  get EditSVG(): string {
    return this._EDIT_SVG;
  }

  get allBanks(): Bank[] {
    return this._ALL_BANKS;
  }

  private _EDIT_SVG = '/assets/svg/question.png';

  private toggleSideBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  toggleSideBarFlag$: Observable<boolean> = this.toggleSideBar$.asObservable();

  private _ALL_BANKS: Bank[] = [
    {name: 'ملت', id: BankEnum.Mellat, imgUrl: '../../../assets/images/banks/mellat.png'},
    {name: 'سامان', id: BankEnum.Saman, imgUrl: '../../../assets/images/banks/saman.png'},
    {name: 'پارسیان', id: BankEnum.Parsian, imgUrl: '../../../assets/images/banks/parsian.png'},
  ];

  convertDateObjectToString({day, month, year}: any): string {
    day = day < 10 ? `0${day}` : String(day);
    month = month < 10 ? `0${month}` : String(month);
    return `${year}${month}${day}`;
  }

  convertTimeObjectToString({hour, minute, second}: any): string {
    hour = hour < 10 ? `0${hour}` : String(hour);
    minute = minute < 10 ? `0${minute}` : String(minute);
    second = second < 10 ? `0${second}` : String(second);
    return `${hour}${minute}${second}`;
  }

  convertStringToTimeObject(expression: any): { hour: number, minute: number, second: number } {
    expression = isNaN(expression) ? expression : String(expression);
    return {
      hour: parseInt(expression.slice(8, 10), 10),
      minute: parseInt(expression.slice(10, 12), 10),
      second: expression.slice(12, 14) ? parseInt(expression.slice(12, 14), 10) : 0
    };
  }

  convertStringToDateObject(expression: any): { day: number, month: number, year: number } {
    expression = isNaN(expression) ? expression : String(expression);
    return {
      day: parseInt(expression.slice(6, 8), 10),
      month: parseInt(expression.slice(4, 6), 10),
      year: parseInt(expression.slice(0, 4), 10)
    };
  }

  getToday(): string {
    const now = new Date();
    const p = MJ(now, 'YYYY-M-D')
    .locale('fa')
    .format('YYYY/MM/DD');
    return p.substr(0, 4) + p.substr(5, 2) + p.substr(8, 2);
  }

  setToggleSideBar(obj: boolean): void {
    this.toggleSideBar$.next(obj);
  }

  convertPersianToEnglish(strNum: string): string {
    let result: string;
    const persianNumbers = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰'];
    const arabicNumbers = ['١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠'];
    const englishNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

    result = strNum.split('').map(c => englishNumbers[persianNumbers.indexOf(c)] || englishNumbers[arabicNumbers.indexOf(c)] || c).join('');
    return result;
  }

  preventAlphabet(input: string): boolean {
    const numbers = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    let result = true;

    input.split('').map((char: string) => {
      result = result && numbers.includes(char);
    });
    return result;
  }

  numericalValidationMultiLang(strNum: string): string {
    let result: string;
    let isValid: boolean;
    isValid = this.preventAlphabet(strNum);
    if (isValid) {
      result = this.convertPersianToEnglish(strNum);
      if (strNum !== result) {
        return result;
      } else {
        return strNum;
      }
    } else {
      return strNum.substring(0, strNum.length - 1);
    }
  }

  // /*  preventAlphabet(input: string): { isValid: boolean, character: string } {
  //     const numbers = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  //     let isValid = true;
  //     let character = '';
  //
  //     // tslint:disable-next-line:no-shadowed-variable
  //     input.split('').map((char: string) => {
  //       // console.log(`include: ${char}`, numbers.includes(char));
  //       // character = char;
  //       if (isValid) {
  //         isValid = isValid && numbers.includes(char);
  //         character = char;
  //       } else {
  //         return;
  //       }
  //     });
  //     return {isValid, character};
  //   }
  //
  //   numericalValidationMultiLang(strNum: string): string {
  //     let result: string;
  //     let state: { isValid: boolean, character: string };
  //     // let isValid: boolean;
  //     state = this.preventAlphabet(strNum);
  //     if (state.isValid) {
  //       result = this.convertPersianToEnglish(strNum);
  //       if (strNum !== result) {
  //         return result;
  //       } else {
  //         return '';
  //       }
  //     } else {
  //       return strNum.replace(state.character, '');
  //       // return strNum.substring(0, strNum.length - 1);
  //     }
  //   }*/

}
