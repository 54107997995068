import {Component, ElementRef, OnInit, Renderer2, ViewChild, ViewChildren} from '@angular/core';
import {MetaService} from './shared/services/meta.service';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {filter, map, mergeMap, take} from 'rxjs/operators';
import {ConfigService} from './shared/services/config.service';
import {TokenService} from './shared/services/token.service';
import {AuthService} from './main/auth/services/auth.service';
import {Meta, Title} from '@angular/platform-browser';
import {LoadingService} from './shared/services/loading.service';
import {interval} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'IPDemy';
  previousUrl = '';
  currentUrl = '';

  loading = true;

  constructor(private metaService: MetaService,
              private router: Router,
              private config: ConfigService,
              private tokenService: TokenService,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private titleService: Title,
              private metaTagService: Meta,
              private renderer: Renderer2,
              private elementRef: ElementRef) {

    const el = this.elementRef.nativeElement.previousElementSibling;
    this.renderer.addClass(el, 'hide');
  }

  ngOnInit(): void {
    // Change page title on navigation  based on route data
    this.titleTag();
    this.defaultRedirect();

    /*const el = this.elementRef.nativeElement.parentElement.getElementsByClassName('first-loading');
    this.renderer.addClass(el[0], 'hope');*/
  }

  defaultRedirect(): void {
    this.currentUrl = decodeURI(this.router.url);
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    )
    // @ts-ignore
    .subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      const prev = this.previousUrl.split('?');
      this.authService.redirectUrl.url = decodeURI(prev.length > 1 ? prev[0] : this.previousUrl);
      this.authService.redirectUrl.qParams = this.activatedRoute.snapshot.queryParams;
    });
  }

  titleTag(): void {
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    )
    .subscribe(res => {
        const title = res.title;
        if (title) {
          const titleText = `پلتفرم آموزش آنلاین - آکادمی ایده پردازان | ${title}`;
          this.titleService.setTitle(titleText);
          this.metaTagService.updateTag({name: 'title', content: titleText});
        }

        const tags = res.metaTags;
        if (tags) {
          for (const tag in tags) {
            if (tags.hasOwnProperty(tag)) {
              this.metaTagService.updateTag({name: tag, content: tags[tag]});
            }
          }
        }
      }
    );
  }
}
