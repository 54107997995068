import {Pipe, PipeTransform} from '@angular/core';
import {WebinarStatusEnum} from '../enum/webinar-status.enum';

@Pipe({
  name: 'webinarStatus'
})
// TODO dont used
export class WebinarStatusPipe implements PipeTransform {

  transform(value: WebinarStatusEnum): string {
    switch (value) {
      case WebinarStatusEnum.NotHeld :
        return 'رویداد پیش رو';
      case WebinarStatusEnum.OnPerforming :
        return 'درحال برگزاری';
      default :
        return 'برگزار شده';
    }
  }

}
