import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {UtilityService} from '../../services/utility.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConfirmModalComponent implements OnInit {
  @Input() svg!: string;
  @Input() modalText!: string;
  @Input() confirmStyle!: string;
  @Input() modalConfirmButton!: string;
  @Input() modalCancelButton!: string;

  constructor(public ngbActiveModal: NgbActiveModal,
              public utilityService: UtilityService) {
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.ngbActiveModal.close(true);
  }

  onCancel(): void {
    this.ngbActiveModal.close(false);
  }
}
