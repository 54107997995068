import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'convertUrl'
})
export class ConvertUrlPipe implements PipeTransform {

  transform(value: string): string {
    const forbiddenWord: number[] = [33, 35, 38, 40, 41, 47, 60, 61, 62, 63];
    const str = value.split('');
    str.forEach((item, index) => {
      const charCode = item.charCodeAt(0);
      if (charCode === 32 || charCode === 8204) {
        str[index] = '-';
      } else if (forbiddenWord.includes(charCode)) {
        str.splice(index, 1);
      }
    });
    value =  str.join('').trim();
    return value;
  }

}
