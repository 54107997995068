import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {filter} from 'rxjs/operators';

import {AuthService} from '../../../auth/services/auth.service';
import {TokenService} from '../../../../shared/services/token.service';
import {MainService} from '../../../main.service';
import {ResizeService} from '../../../../shared/services/resize.service';
import {CategoryService} from '../../../modules/global/category/service/category.service';
import {UtilityService} from '../../../../shared/services/utility.service';
import {ConvertUrlPipe} from '../../../../shared/pipes/convert-url.pipe';
import {DeviceType} from '../../../../shared/models/device-type';
import {MainCategoryModel} from '../../../modules/global/category/models/main-category.model';
import {SubCategoryModel} from '../../../modules/global/category/models/sub-category.model';

@UntilDestroy()
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  menuList: MainCategoryModel[];
  openClass = false;
  deviceType!: DeviceType;
  hasHome = false;

  constructor(public resize: ResizeService,
              private categoryService: CategoryService,
              private router: Router,
              public tokenService: TokenService,
              public auth: AuthService,
              private convertUrl: ConvertUrlPipe,
              public mainService: MainService,
              private utilityService: UtilityService) {
    this.resize.deviceMode
      .pipe(untilDestroyed(this))
      .subscribe(f => {
        if (f !== 'xs' && f !== 'sm') {
          this.openClass = false;
        }
        this.deviceType = f;
      });

    const specialMenu: MainCategoryModel[] = [];
    categoryService.categoryList.forEach(f => {
      if (f.isSpecial) {
        specialMenu.push(f);
      }
    });
    this.menuList = JSON.parse(JSON.stringify(specialMenu));

    /*const menuForAll = {
      id: null,
      imageUrl: categoryService.categoryList[0].imageUrl,
      isSpecial: false,
      itemCount: 0,
      subsets: [],
      title: 'همه',
    };
    const allItemsCount = this.menuList.reduce((sum, a) => {
      console.log(sum);
      console.log(a.itemCount);
      return sum + a.itemCount;
    }, 0);

    menuForAll.itemCount = allItemsCount;
    categoryService.categoryList.unshift(menuForAll);*/
    // this.menuList.unshift(menuForAll);

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd)
      )
      // @ts-ignore
      .subscribe((res: NavigationEnd) => {
        this.hasHome = (res.url === '/home' || res.url === '/');
      });
  }

  expandHandler(event: any, nested: boolean): void {
    if (nested) {
      const m = document.getElementsByClassName('menu_item__open');
      const submenu = document.getElementsByClassName('submenu_item__open');
      const rotate = document.getElementsByClassName('rotate');
      Array.prototype.forEach.call(m, (el) => {
        if (el !== event.currentTarget.nextElementSibling) {
          el.classList.remove('menu_item__open');
        }
      });
      Array.prototype.forEach.call(submenu, (el) => {
        if (el !== event.currentTarget.nextElementSibling) {
          el.classList.remove('submenu_item__open');
        }
      });
      Array.prototype.forEach.call(rotate, (el) => {
        if (el !== event.currentTarget) {
          el.classList.remove('rotate');
        }
      });
      if (event.currentTarget.nextElementSibling.classList.contains('menu_item__open')) {
        event.currentTarget.classList.remove('rotate');
        event.currentTarget.nextElementSibling.classList.remove('menu_item__open');
      } else {
        event.currentTarget.classList.add('rotate');
        event.currentTarget.nextElementSibling.classList.add('menu_item__open');
      }
    } else {
      const m = document.getElementsByClassName('submenu_item__open');
      Array.prototype.forEach.call(m, (el) => {
        if (el !== event.currentTarget.nextElementSibling) {
          el.classList.remove('submenu_item__open');
        }
      });
      const rotate = event.currentTarget.parentElement.parentElement.getElementsByClassName('rotate');
      Array.prototype.forEach.call(rotate, (el) => {
        if (el !== event.currentTarget) {
          el.classList.remove('rotate');
        }
      });
      if (event.currentTarget.nextElementSibling.classList.contains('submenu_item__open')) {
        event.currentTarget.nextElementSibling.classList.remove('submenu_item__open');
        event.currentTarget.classList.remove('rotate');
      } else {
        event.currentTarget.nextElementSibling.classList.add('submenu_item__open');
        event.currentTarget.classList.add('rotate');
      }
    }
  }

  menuHandler(): void {
    this.openClass = !this.openClass;
  }

  closeMenu(): void {
    this.openClass = false;
  }

  gotoProfile(): void {
    this.openClass = false;

    // const url = '/user/profile/personal-information';
    const url = '/user/purchased-products';
    this.router.navigateByUrl(url);
    this.utilityService.setToggleSideBar(true);
  }

  subsetNavigate(mainMenu: MainCategoryModel, subMenu: SubCategoryModel): void {
    const targetRoute = 'category/' + mainMenu.id + '/' + this.convertUrl.transform(mainMenu.title) + '?subsets=' + subMenu.id;

    this.router.navigateByUrl(targetRoute);

    const mainCategoryFilterList = this.categoryService.getFilterList();
    mainCategoryFilterList.subsets.forEach(x => {
      if (x.id === subMenu.id) {
        x.isSelected = true;
      } else {
        x.isSelected = false;
      }
    });

    this.categoryService.isInitialCallAPI = false;
    this.categoryService.setFilterItem(mainCategoryFilterList);

    this.closeMenu();
  }

  changeRoute(newRoute: string): void {
    this.router.navigateByUrl(newRoute);
    this.closeMenu();
  }
}
