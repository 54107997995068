import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

  @Input() value!: string;
  @Input() name!: string;
  @Input() label!: string;
  @Input() id!: string;
  @Input() checkValue!: string;
  @Input() disabled = false;
  @Input() isImg = false;

  @Output() changeEvent = new EventEmitter<Event>();

  checked!: boolean;

  constructor() {
  }

  // TODO: event type model
  checkedEmit(event: any): void {
    this.checked = event.target.checked;
    this.changeEvent.emit(event);
  }

  ngOnInit(): void {
    if (this.checked === undefined) {
      this.checked = this.value === this.checkValue;
    }
  }

}
