import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NumericOnlyDirective} from './directives/numeric-only.directive';
import {NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CounterComponent} from './components/counter/counter.component';
import {FormatTimePipe} from './pipes/format-time';
import {ConvertDatePipe} from './pipes/convert-date.pipe';


import {RouterModule} from '@angular/router';
import {TextRestrictionsComponent} from './components/text-restrictions/text-restrictions.component';
import {DateTimePipe} from './pipes/date-time.pipe';
import {ConvertMinutesPipe} from './pipes/convert-minutes.pipe';
import {RadioButtonComponent} from './components/radio-button/radio-button.component';
import {ConvertUrlPipe} from './pipes/convert-url.pipe';
import {TableComponent} from './components/table/table.component';
import {ToggleSwitchComponent} from './components/toggle-switch/toggle-switch.component';
import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import {ConvertPricePipe} from './pipes/convert-price.pipe';
import {MentorPrefixPipe} from './pipes/mentor-prefix.pipe';
import {WebinarStatusPipe} from './pipes/webinar-status.pipe';
import {WebinarSectionTitlePipe} from './pipes/meetings-title.pipe';
import {SafeSanitizing} from './pipes/safe-sanitizing';
import {ConvertPriceWithCommaPipe} from './pipes/convert-price-with-comma.pipe';
import {IncreaseDecreaseComponent} from './components/increase-decrease/increase-decrease.component';
import {MentorCartComponent} from './components/mentor-cart/mentor-cart.component';
import {StopPropagationDirective} from './directives/stop-propagation.directive';
import {TableActionTranslatePipe} from './components/table/table-action-translate.pipe';


const sharedDeclarations = [
  NumericOnlyDirective,
  CounterComponent,
  FormatTimePipe,
  ConvertDatePipe,
  TextRestrictionsComponent,
  DateTimePipe,
  ConvertMinutesPipe,
  RadioButtonComponent,
  ConvertUrlPipe,
  TableComponent,
  ToggleSwitchComponent,
  ConfirmModalComponent,
  ConvertPricePipe,
  MentorPrefixPipe,
  WebinarStatusPipe,
  WebinarSectionTitlePipe,
  SafeSanitizing,
  ConvertPriceWithCommaPipe,
  IncreaseDecreaseComponent,
  MentorCartComponent,
  StopPropagationDirective,
  TableActionTranslatePipe
];

@NgModule({
  declarations: [
    ...sharedDeclarations,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbPaginationModule
  ],
  exports: [
    ...sharedDeclarations,
    NgbModule,
  ],

  providers: [ConvertUrlPipe],
})

export class SharedModule {

}
