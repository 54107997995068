<!--desktop-->
<ul class="menu_list"
    [ngClass]="{'home': hasHome}"
    *ngIf="deviceType !== 'xs' && deviceType !== 'sm'">
  <li class="menu_item">
    <a routerLink="/home" routerLinkActive="active">
      <div class="menu_text">
        <span>صفحه اصلی</span>
      </div>
    </a>
  </li>
  <li class="menu_item" *ngFor="let menu of menuList">
    <a
      routerLink="category/{{menu.id ? menu.id + '/' + (menu.title | convertUrl) : 'all/' + (menu.title | convertUrl)}}"
      routerLinkActive="active">
      <div class="menu_text">
        <span>{{menu.title}}</span>
      </div>
    </a>
  </li>
</ul>

<!--mobile-->
<button (click)="menuHandler()"
        type="reset"
        class="menu_btn"
        [ngClass]="{'home': hasHome}"
        *ngIf="deviceType === 'xs' || deviceType === 'sm'">
  <i class="fas fa-bars"></i>
</button>
<div class="menu_mobile" *ngIf="deviceType === 'xs' || deviceType === 'sm'">
  <div [ngClass]="{'open':openClass }" class="menu_mobile_wrapper">
    <div class="login_btn">
      <button *ngIf="!(tokenService.isAuthenticated$ | async)" (click)="closeMenu()" class="btn btn-login"
              [routerLink]="['/auth/login']"
              [routerLinkActive]="['active']">ورود/ثبت نام
      </button>

      <div *ngIf="(tokenService.isAuthenticated$ | async)"
           (click)="gotoProfile()"
           class="login_btn_user"
      >
        <i class="fas fa-user"></i>
        <div class="login_btn_user_name" *ngIf="{userData:mainService.userData$ | async} as O">
          <ng-container *ngIf="O.userData !== null">
            <span class="name" *ngIf="O.userData.fullName">{{O.userData.fullName}}</span>
            <span class="name" *ngIf="!O.userData.fullName">بدون نام</span>
          </ng-container>
          <div class="login_btn_user_name_view">
            <span>مشاهده حساب کاربری</span>
            <i class="far fa-angle-left"></i>
          </div>
        </div>
      </div>

    </div>

    <ul class="menu_mobile_list">
      <li class="menu_mobile_item">
        <a
          (click)="closeMenu()"
          routerLink="/home"
          routerLinkActive="active">
          <div class="menu_text">
            <span>صفحه اصلی</span>
          </div>
        </a>
      </li>
      <li class="menu_mobile_item"
          *ngFor="let menu of menuList">
        <a
          (click)="closeMenu()"
          routerLink="category/{{menu.id ? menu.id + '/' + (menu.title | convertUrl) : 'all/' + (menu.title | convertUrl)}}"
          routerLinkActive="active">
          <div class="menu_text">
            <span>{{menu.title}}</span>
          </div>
        </a>
      </li>
    </ul>

    <div *ngIf="(tokenService.isAuthenticated$ | async)" class="menu_mobile_logout">
      <button (click)="auth.logOut()">
        <i class="fas fa-sign-out-alt"></i>
        <span>خروج از حساب کاربری</span>
      </button>
    </div>

  </div>

  <button (click)="menuHandler()" [ngClass]="{'open':openClass}">
    <i class="fas fa-times"></i>
  </button>
  <span (click)="menuHandler()" [ngClass]="{'open':openClass}"></span>
</div>
