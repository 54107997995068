import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

import {ApiService} from '../../../shared/services/api.service';
import {TokenService} from '../../../shared/services/token.service';
import {ResponseModel} from '../../../shared/models/response.model';
import {LoginResultModel} from '../models/login-result.model';
import {AuthenticationStateModel} from '../models/authentication-state.model';
import {VerifyVerificationCodeResultModel} from '../models/verify-verification-code-result.model';
import {UserAuthenticationStateModel} from '../models/user-authentication-state.model';
import {AddVerifyVerificationCodeModel} from '../models/add-verify-verification-code.model';
import {AddSetPasswordModel} from '../models/add-set-password.model';
import {AddLoginModel} from '../models/add-login.model';
import {AddVerificationCodeModel} from '../models/add-verification-code.model';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private authStepSubject: BehaviorSubject<string>;
  stepStep$: Observable<string>;

  private userAuthenticationState!: BehaviorSubject<UserAuthenticationStateModel>;
  userAuthState$: Observable<UserAuthenticationStateModel>;

  redirectUrl = {
    url: '',
    mainUrl: '',
    qParams: {}
  };

  constructor(private apiService: ApiService,
              private tokenService: TokenService,
              private router: Router) {
    this.authStepSubject = new BehaviorSubject('mobileStep');
    this.stepStep$ = this.authStepSubject.asObservable();
    // @ts-ignore
    this.userAuthenticationState = new BehaviorSubject<UserAuthenticationStateModel>(undefined);
    this.userAuthState$ = this.userAuthenticationState.asObservable();
  }

  setStep(step: string): void {
    this.authStepSubject.next(step);
  }

  setUserAuthState(obj: UserAuthenticationStateModel): void {
    return this.userAuthenticationState.next(obj);
  }

  verificationSendHandler(payload: any): Observable<ResponseModel<AuthenticationStateModel>> {
    const url = 'global/auth/state';
    return this.apiService.post(url, payload, false);
  }

  SendVerificationCodeHandler(payload: AddVerificationCodeModel): Observable<ResponseModel<VerifyVerificationCodeResultModel>> {
    const url = 'global/auth/verificationCode';
    return this.apiService.post(url, payload, false);
  }

  verificationVerifyHandler(payload: AddVerifyVerificationCodeModel): Observable<ResponseModel<VerifyVerificationCodeResultModel>> {
    const url = 'global/auth/verificationCode';
    return this.apiService.put(url, payload, false);
  }

  setPasswordHandler(payload: AddSetPasswordModel): Observable<ResponseModel<LoginResultModel>> {
    const url = 'global/auth/password';
    return this.apiService.put(url, payload, false);
  }

  loginWithPasswordVerificationHandler(payload: AddLoginModel): Observable<ResponseModel<LoginResultModel>> {
    const url = 'global/auth/login';
    return this.apiService.post(url, payload, false);
  }

  setUserPasswordHandler(payload: string): Observable<ResponseModel<boolean>> {
    const url = 'secured/user/password';
    return this.apiService.patch(url, payload, true);
  }

  logoutHandler(payload: string): Observable<ResponseModel<boolean>> {
    const body = JSON.stringify(payload);
    const url = 'global/Auth/logout';
    return this.apiService.post(url, body, false);
  }

  //
  // keepAlive(): void {
  //   const seconds = this.tokenService.secondsToExpire();
  //   interval(seconds * 1000)
  //     .pipe(take(1),
  //     ).subscribe(value => {
  //     this.refreshToken();
  //   });
  // }
  //
  // refreshToken(): void {
  //   const token = this.tokenService.getRefreshToken();
  //   this.apiService.patch('global/Auth/token', JSON.stringify(token), false)
  //     .subscribe((res: ResponseModel<LoginResultModel>) => {
  //       this.tokenService.setTokenItems(res.result);
  //       this.tokenService.setIsAuthenticated(true);
  //       this.keepAlive();
  //     });
  // }

  logOut(): void {
    this.logoutHandler(this.tokenService.getRefreshToken());
    this.tokenService.removeAllToken();
    this.tokenService.setIsAuthenticated(false);
    this.setStep('mobileStep');
    const segmentRoute = this.router.routerState.snapshot.url.split('/');
    if (segmentRoute[1] === 'user') {
      this.router.navigate(['home']).then();
    }
  }

}
