import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuard} from './main/auth/guards/auth-guard.guard';
import {MainComponent} from './main/main.component';
import {MainResolverService} from './shared/services/main-resolver.service';
import {AuthenticatedGuard} from './shared/guards/authenticated.guard';
import {NotFoundComponent} from './not-found/not-found.component';
import {BaseComponent} from './base/base.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';


const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    resolve: {mainInfo: MainResolverService},
    children: [
      {
        path: 'auth',
        canActivate: [AuthGuard],
        data: {
          title: 'ورود و ثبت نام',
          metaTags: {
            keywords: 'آکادمی ایده پردازان، وبینار، ipdemy',
            description: 'آکادمی ایده پردازان ارائه کننده بهترین و بروزترین وبینارها در حوزه موفقیت و توسعه فردی، شغلی، فناوری اطلاعات، تکنولوژی است.',
          }
        },
        loadChildren: () => import('./main/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: '', component: MainComponent,
        children: [
          {path: '', redirectTo: 'home', pathMatch: 'full'},
          {
            path: 'home',
            data: {
              title: 'مهارت‌های مدیریت، بازاریابی و فروش، رشد و توسعه فردی',
              metaTags: {
                keywords: 'آکادمی ایده پردازان، وبینار، ipdemy',
                description: 'آکادمی ایده پردازان ارائه کننده بهترین و بروزترین وبینارها در حوزه موفقیت و توسعه فردی، شغلی، فناوری اطلاعات، تکنولوژی است.',
                robots: 'index, follow'
              }
            },
            loadChildren: () => import('./main/modules/global/home/home.module').then(m => m.HomeModule)
          },
          {
            path: 'faq',
            data: {
              title: 'سوالات متداول',
              metaTags: {
                keywords: 'آکادمی ایده پردازان، وبینار, ipdemy, سوالات متداول',
                description: 'آکادمی ایده پردازان ارائه کننده بهترین و بروزترین وبینارها در حوزه موفقیت و توسعه فردی، شغلی، فناوری اطلاعات، تکنولوژی است.',
                robots: 'index, follow'
              }
            },
            loadChildren: () => import('./main/modules/global/faq/faq.module').then(m => m.FaqModule)
          },
          {
            path: 'cooperation-request',
            data: {
              title: 'همکاری با ما',
              metaTags: {
                keywords: 'آکادمی ایده پردازان، وبینار، ipdemy، مدرس، همکاری با ایده پردازان',
                description: 'مدرس آکادمی شوید. برای همکاری با آکادمی ایده پردازان فقط کافیست با ما در ارتباط باشید. ',
                robots: 'index, follow'
              }
            },
            loadChildren: () => import('./main/modules/global/cooperation-request/cooperation-request-module')
              .then(m => m.CooperationRequestModule)
          },
          {
            path: 'contact-us',
            data: {
              title: 'تماس با ما',
              metaTags: {
                keywords: 'آکادمی ایده پردازان، وبینار، ipdemy، تماس با آکادمی ایده پردازان',
                description: 'آکادمی ایده پردازان ارائه کننده بهترین و بروزترین وبینارها در حوزه موفقیت و توسعه فردی، شغلی، فناوری اطلاعات، تکنولوژی است.',
                robots: 'index, follow'
              }
            },
            loadChildren: () => import('./main/modules/global/contact-us/contact-us.module').then(m => m.ContactUsModule)
          },
          {
            path: 'help',
            data: {
              title: 'راهنما',
              metaTags: {
                keywords: 'آکادمی ایده پردازان، وبینار، ipdemy، راهنمای استفاده از آکادمی ایده پردازان',
                description: 'آکادمی ایده پردازان ارائه کننده بهترین و بروزترین وبینارها در حوزه موفقیت و توسعه فردی، شغلی، فناوری اطلاعات، تکنولوژی است.',
                robots: 'index, follow'
              }
            },
            loadChildren: () => import('./main/modules/global/help/help.module').then(m => m.HelpModule)
          },
          {
            path: 'mentors',
            loadChildren: () => import('./main/modules/global/mentors/mentors.module').then(m => m.MentorsModule)
          },
          {
            path: 'category',
            loadChildren: () => import('./main/modules/global/category/category.module').then(m => m.CategoryModule)
          },
          {
            path: 'about',
            data: {
              title: 'درباره ما',
              metaTags: {
                keywords: 'آکادمی ایده پردازان، وبینار، ipdemy، درباره آکادمی',
                description: 'آکادمی ایده پردازان ارائه کننده بهترین و بروزترین وبینارها در حوزه موفقیت و توسعه فردی، شغلی، فناوری اطلاعات، تکنولوژی است.',
                robots: 'index, follow'
              }
            },
            loadChildren: () => import('./main/modules/global/about/about.module').then(m => m.AboutModule)
          },
          {
            path: 'webinar/:id/:title',
            loadChildren: () => import('./main/modules/global/webinar/webinar.module').then(m => m.WebinarModule)
          },
          {
            path: 'webinar/:id',
            loadChildren: () => import('./main/modules/global/webinar/webinar.module').then(m => m.WebinarModule)
          },
          {
            path: 'user',
            canActivate: [AuthenticatedGuard],
            loadChildren: () => import('./main/modules/secured/user.module').then(m => m.UserModule)
          },
          {
            path: 'search-result',
            data: {
              title: 'نتایج جستجو',
              metaTags: {
                keywords: 'آکادمی ایده پردازان، وبینار, ipdemy, جستجو',
                description: 'آکادمی ایده پردازان ارائه کننده بهترین و بروزترین وبینارها در حوزه موفقیت و توسعه فردی، شغلی، فناوری اطلاعات، تکنولوژی است.',
                robots: 'index, follow'
              }
            },
            loadChildren: () => import('./main/modules/global/search/search.module').then(m => m.SearchModule)
          }
        ]
      },
    ]
  },
  {
    path: 'maintenance',
    data: {title: 'maintenance'},
    component: MaintenanceComponent
  },
  {
    path: '404',
    data: {title: 'not found'},
    component: NotFoundComponent
  },
  {
    path: '**',
    data: {title: 'not found'},
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
