import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-text-restrictions',
  templateUrl: './text-restrictions.component.html',
  styleUrls: ['./text-restrictions.component.scss']
})
export class TextRestrictionsComponent implements OnInit {
  @Input() content!: string;
  isLimit = false;
  contentHeight!: number;
  isShow = false;

  constructor() {
  }

  ngOnInit(): void {
    if (this.content.length > 1000) {
      this.isLimit = true;
      this.contentHeight = 240;
    }
  }

  show(): void {
    const el = document.getElementsByClassName('text_restrictions')[0].firstChild;
    if (this.contentHeight !== 240) {
      this.contentHeight = 240;
      this.isShow = false;
    } else {
      // @ts-ignore
      this.contentHeight = el.scrollHeight;
      this.isShow = true;
    }
  }
}
