import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ConfigService} from './config.service';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  httpOptions: any = {
    headers: new HttpHeaders({'Content-Type': 'application/json', 'Need-Loading': 'false'}),
    observe: 'response',
    params: {}
  };

  private readonly serverURL: string;

  constructor(private httpClient: HttpClient,
              private tokenService: TokenService,
              private configService: ConfigService) {

    this.serverURL = `${this.configService.baseUrl}/api/`;
  }

  get<T>(url: string, needAuth: boolean, queryParams?: any, needLoading = false): Observable<T> {
    const queryParamsLocal = {...queryParams};

    if (queryParams) {
      for (const key in queryParamsLocal) {
        if (queryParamsLocal.hasOwnProperty(key)) {
          if (queryParamsLocal[key] === undefined || queryParamsLocal[key] === null) {
            delete queryParamsLocal[key];
          }
        }
      }
    }

    this.httpOptions.params = queryParamsLocal;
    this.httpOptions.headers = this.httpOptions.headers.set('Need-Loading', needLoading ? 'true' : 'false');

    if (needAuth) {
      this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + this.tokenService.getAccessToken());
    }

    return this.httpClient.get<T>(this.serverURL + url, this.httpOptions)
    .pipe(
      map((res: any) => {
        return res.body;
      })
    );
  }

  post<T>(url: string, payload: T, needAuth: boolean, file?: any, needLoading = false): any {
    this.httpOptions.headers = this.httpOptions.headers.set('Need-Loading', needLoading ? 'true' : 'false');
    if (needAuth) {
      this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + this.tokenService.getAccessToken());
    }

    return this.httpClient.post<T>((this.serverURL) + url, payload, this.httpOptions)
    .pipe(
      map((res: any) => {
        return res.body;
      })
    );
  }

  put<T>(url: string, payload: T, needAuth: boolean, needLoading = false): any {
    this.httpOptions.headers = this.httpOptions.headers.set('Need-Loading', needLoading ? 'true' : 'false');
    if (needAuth) {
      this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + this.tokenService.getAccessToken());
    }

    return this.httpClient.put<T>((this.serverURL) + url, payload, this.httpOptions)
    .pipe(
      map((res: any) => {
        return res.body;
      })
    );
  }

  patch<T>(url: string, payload: T, needAuth: boolean): Observable<any> {
    if (needAuth) {
      this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + this.tokenService.getAccessToken());
    }

    return this.httpClient.patch<T>((this.serverURL) + url, payload, this.httpOptions)
    .pipe(
      map((res: any) => {
        return res.body;
      })
    );
  }

  delete<T>(url: string, needAuth: boolean, payload?: T): any {
    if (payload) {
      this.httpOptions.body = payload;
    }
    if (needAuth) {
      this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + this.tokenService.getAccessToken());
    }

    /*let options: any = {
      observe: this.httpOptions.observe
    };
    if (payload) {
      options = {
        ...options,
        body: payload
      };
    }
    if (needAuth) {
      options = {
        ...options,
        headers: this.httpOptions.headers.append('Authorization', 'Bearer ' + this.tokenService.getAccessToken())
      };
    }*/

    return this.httpClient.delete<T>((this.serverURL) + url, this.httpOptions)
    .pipe(
      map((res: any) => {
        return res.body;
      })
    );
  }

  putFile(url: string, file: any): any {
    return this.httpClient.put((this.serverURL) + url, file, {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.getAccessToken()
      })
    })
    .pipe(
      map((res: any) => {
        return res.body;
      })
    );
  }

  getFile(url: string): any {
    const headers = new HttpHeaders({
      Accept: 'application/pdf',
      Authorization: 'Bearer ' + this.tokenService.getAccessToken(),
    });

    return this.httpClient.get((this.serverURL) + url, {headers, responseType: 'blob'})
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
