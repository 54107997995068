<div class="increase-decrease-wrapper">
  <button [disabled]="minusBtnDisabled" [ngClass]="{'disabled':minusBtnDisabled}" type="button" class="minus-btn"
          (click)="quantityHandler(false)">
    <i class="fas fa-minus"></i>
  </button>
<!--  <input type="number" [value]="currentValue" (input)="inputChangeHandler($event)" [min]="min" [max]="max">-->
  <span>{{currentValue}}</span>
  <button [disabled]="plusBtnDisabled" [ngClass]="{'disabled':plusBtnDisabled}" type="button" class="plus-btn"
          (click)="quantityHandler(true)">
    <i class="fas fa-plus"></i>
  </button>
</div>
