<footer class="footer">
  <div class="container">
    <div class="newsletter">
      <div class="row">
        <div class="col-12 col-md-12 order-1">
          <p>با عضویت در خبرنامه هیچ وبیناری را از دست نخواهید داد.</p>
          <form [formGroup]="newsLetters" (ngSubmit)="subscribeToNewsletter(newsLetters.value)">
            <div class="footer_form">
              <input type="text"
                     placeholder="مثلا example@gmail.com"
                     maxlength="128"
                     formControlName="email">
              <button type="submit" [disabled]="!newsLetters.valid">عضویت</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="top-row">
      <div class="row">
        <!--Logo container-->
        <div class="col-6 col-md-3 col-sm-6">
          <div class="footer_logo_wrapper">
            <a routerLink="/" routerLinkActive="active">
              <img src="assets/images/logo.png" alt="آکادمی ایده پردازان">
            </a>
          </div>

          <div class="footer_social">
              <a href="https://www.instagram.com/idehpardazan.studio/" target="_blank">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="https://wa.me/989129573698" target="_blank">
                <i class="fab fa-whatsapp"></i>
              </a>
          </div>
        </div>

        <div class="col-6 col-md-3 col-sm-6">
          <div class="footer_menu">
            <h6>آکادمی</h6>
            <ul>
              <li>
                <a routerLink="/contact-us" routerLinkActive="active">ارتباط با ما</a>
              </li>
              <li>
                <a routerLink="/mentors" routerLinkActive="active">لیست مدرسین</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-6 col-md-3 col-sm-6">
          <div class="footer_menu">
            <h6>دسته بندی</h6>
            <ul>
              <li *ngFor="let menuItem of specialMenu">
                <a [routerLink]="['../category/', menuItem.id, menuItem.title | convertUrl]"> {{menuItem.title}}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-6 col-md-3 col-sm-6">
          <div class="footer_menu">
            <h6>دسترسی سریع</h6>
            <ul>
              <li>
                <a routerLink="/faq" routerLinkActive="active">سوالات متداول</a>
              </li>
              <li>
                <a routerLink="/help" routerLinkActive="active">راهنمای پخش آنلاین</a>
              </li>
              <li>
                <a routerLink="/help/warranty" routerLinkActive="active">گارانتی اثربخشی</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-row">
      <div class="row">
        <div class="col-12 col-md-10">
          <p class="copyright">
            تمامی حقوق مادی و معنوی این وب‌سایت متعلق به <a href="https://ipe.ir/" target="_blank">ایده‌پردازان</a> می‌باشد. می باشد و هر گونه کپی برداری پیگرد قانونی دارد.
          </p>

          <p class="related-links">
            <span> IPE.IR </span>
            <span> IPDEV.IR </span>
            <span> MMS.IR </span>
            <span> IPDEMY.IR </span>
          </p>
        </div>

        <div class="col-md-2 text-left">
          <div class="electronic_trust_symbol">
            <a referrerpolicy="origin"
               target="_blank"
               href="https://trustseal.enamad.ir/?id=238215&amp;Code=94stDHEU0CbeIAl2VbsI">
              <img referrerpolicy="origin"
                   src="../../../../assets/images/ets.png"
                   alt="electronic_trust_symbol"
                   style="cursor:pointer"
                   id="94stDHEU0CbeIAl2VbsI"></a>
          </div>
        </div>
      </div>
    </div>

  </div>
</footer>
