import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgbPagination, NgbPaginationNext, NgbPaginationNumber, NgbPaginationPrevious} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {ResizeService} from '../../services/resize.service';
import {TableConfigModel} from '../../models/table-config';
import {DeviceType} from '../../models/device-type';
import {ActionDispatchModel} from '../../models/action-dispatch.model';

@UntilDestroy()

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgbPagination, NgbPaginationNext, NgbPaginationPrevious, NgbPaginationNumber]
})

export class TableComponent implements OnInit, OnChanges {
  @Output() actionDispatched: EventEmitter<ActionDispatchModel> = new EventEmitter<ActionDispatchModel>();
  @Output() pageChangedDispatched: EventEmitter<number> = new EventEmitter<number>();
  @Input() tableConfig!: TableConfigModel;
  @Input() dataTable!: any;

  dataTableSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dataTable$: Observable<any> = this.dataTableSubject$.asObservable();

  ObjectKey = Object.keys;
  deviceMode!: DeviceType;

  constructor(public resize: ResizeService) {
    this.resize.deviceMode
      .pipe(untilDestroyed(this))
      .subscribe(d => this.deviceMode = d);

    this.dataTableSubject$.next(this.dataTable);
  }

  ngOnInit(): void {
    this.dataTableSubject$.next(this.dataTable);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataTable.currentValue) {
      this.dataTableSubject$.next(changes.dataTable.currentValue);
      // if (changes.dataTable.firstChange) {
      //   this.firstLoad = true;
      // }
    }
  }

  fireAction(actionTitle: string, data: any): any {
    this.actionDispatched.emit({actionTitle, data});
  }

  pageChanged(pageNumber: number): any {
    this.pageChangedDispatched.emit(pageNumber);
  }

}
